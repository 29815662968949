import React from "react";

const TermItem = ({content}: {content: string}) => {
  return (
    <section className={'bg-neutrals-90 pt-32 pb-20 sm:px-10'}>
      <div className={'policy max-w-[1248px] mx-auto bg-white px-5 py-10 sm:p-10 sm:rounded'} dangerouslySetInnerHTML={{__html: content}}/>
    </section>
  )
}

export default TermItem;
